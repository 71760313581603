import React from 'react';
import Main from './components/Main';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(ScrollTrigger);

function App() {
  useGSAP(() => {
    gsap.to('.team_banner_gsap_trigger', {
      backgroundPositionX: '500px',
      scrollTrigger: {
        trigger: '.team_banner_gsap_trigger',
        scrub: 0,
        // markers: true,
        id: 'team_banner',
      },
    });

    gsap.to('.pixelland_banner_gsap_trigger', {
      backgroundPositionX: '500px',
      scrollTrigger: {
        trigger: '.pixelland_banner_gsap_trigger',
        scrub: 0,
        // markers: true,
        id: 'pixelland_banner',
      },
    });
  }, {});

  return <Main />;
}

export default App;
