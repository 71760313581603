export type GamesItemType = {
  name: JSX.Element;
  text: JSX.Element;
  icon: string;
  image: string;
  mob_image: string;
  appStoreUrl?: string;
  googlePlayUrl?: string;
};
export const GAMES: GamesItemType[] = [
  {
    name: (
      <span>
        Crush into ball:
        <br />
        ASMR Smash DIY
      </span>
    ),
    text: (
      <span>
        ASMR Games - Smash it all! But be careful don't smash the wrong color! Time to smash & play! This is Crush into
        a ball, and it’s specifically designed to relieve your stress and keep you entertained by crashing random
        objects. Will it Crush? Play in a boring class or meeting. Free to play.
      </span>
    ),
    icon: 'images/games/icons/itunesartwork_7.webp',
    image: 'images/games/screenshots/screenshot_7.webp',
    mob_image: 'images/games/screenshots/mobile/screenshot_7.webp',
    appStoreUrl: 'https://apps.apple.com/us/app/crush-into-ball-fruit-surgery/id1580960059',
    googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.wannaplay.crushball&hl=en',
  },
  {
    name: (
      <span>
        Kick Giant:
        <br />
        Monster Hunter
      </span>
    ),
    text: (
      <span>
        Meet a fun crush-ing game! It's time to take up arms! Defeat them if you can!
        <br />
        <br />
        Giant Crush is an antidepressant game. If you're feeling stressed or angry, these toys will help you.
      </span>
    ),
    icon: 'images/games/icons/itunesartwork_1.webp',
    image: 'images/games/screenshots/screenshot_1.webp',
    mob_image: 'images/games/screenshots/mobile/screenshot_1.webp',
    appStoreUrl: 'https://apps.apple.com/us/app/kick-giant-assassin-master/id1600235169',
    googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.wannaplay.giantcrush&hl=en',
  },
  {
    name: (
      <span>
        My Talking Slimy:
        <br />
        Super Cat 3D
      </span>
    ),
    text: (
      <span>
        My Talking Slimy is the best virtual slimy game for kids. You can find a lot of activities for your pet. Your
        kid needs to care about Slimy every day, making sure he sleeps enough, get enough food, taking him to toilet and
        keeping him happy by squishing and playing games.
      </span>
    ),
    icon: 'images/games/icons/itunesartwork_2.webp',
    image: 'images/games/screenshots/screenshot_2.webp',
    mob_image: 'images/games/screenshots/mobile/screenshot_2.webp',
    appStoreUrl: 'https://apps.apple.com/us/app/my-talking-slimy-super-cat-3d/id1638724989',
    googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.pixeland.talkingbuddy&hl=en',
  },
  {
    name: (
      <span>
        DIY Building
        <br />
        Construction Game
      </span>
    ),
    text: (
      <span>
        No time-consuming strategies and difficult scenarios. The game is antistress. ASMR building gives you relaxing
        and satisfying feeling as it is user-friendly and super easy game. You can build your great house within several
        minutes!
      </span>
    ),
    icon: 'images/games/icons/itunesartwork_3.webp',
    image: 'images/games/screenshots/screenshot_3.webp',
    mob_image: 'images/games/screenshots/mobile/screenshot_3.webp',
    appStoreUrl: 'https://apps.apple.com/us/app/diy-building-3d-craft-block/id1636470325',
    googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.alpacagames.asmrbuilding&hl=en',
  },
  {
    name: (
      <span>
        Slimy Makeover:
        <br />
        Magic Makeup
      </span>
    ),
    text: (
      <span>
        Enter the thrilling world of Slimy Makeover – your very own mobile video editor & maker for character
        transformation!
        <br />
        <br />
        Here, you can completely change your unique hero's hairstyle and beard.
      </span>
    ),
    icon: 'images/games/icons/itunesartwork_4.webp',
    image: 'images/games/screenshots/screenshot_4.webp',
    mob_image: 'images/games/screenshots/mobile/screenshot_4.webp',
    googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.slimy.makeover&hl=en',
  },
  {
    name: (
      <span>
        Lumberjack Challenge:
        <br />
        Logging
      </span>
    ),
    text: (
      <span>
        We’ve noticed a bored expression on your face! Daily routine… But! Have you ever tried to be a lumberjack? Do
        not freak out, it is super fun! Let’s build your fabulous lumber empire right now!
      </span>
    ),
    icon: 'images/games/icons/itunesartwork_5.webp',
    image: 'images/games/screenshots/screenshot_5.webp',
    mob_image: 'images/games/screenshots/mobile/screenshot_5.webp',
    appStoreUrl: 'https://apps.apple.com/us/app/lumber-jack-challenge-tycoon/id1633401306',
  },
  {
    name: (
      <span>
        Tricky Bricky:
        <br />
        Brain Teasers
      </span>
    ),
    text: (
      <span>
        Want to increase your IQ level? Are you ready to blow your mind from the very first levels? In all those cases
        and more, Tricky Bricky is right for you! This is not another IQ challenge!
      </span>
    ),
    icon: 'images/games/icons/itunesartwork_6.webp',
    image: 'images/games/screenshots/screenshot_6.webp',
    mob_image: 'images/games/screenshots/mobile/screenshot_6.webp',
    appStoreUrl: 'https://apps.apple.com/us/app/tricky-bricky-brain-games-3d/id1497100207',
    googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.trickybricky&hl=en',
  },
];
