import { Box, ButtonBase, Typography } from "@mui/material"



const Button = ({ text, label, onClick }: { text:string,label?: boolean, onClick: ()=>void }) => {
    return (
        <ButtonBase
            sx={{
                position: 'relative',
                padding: '5px 0 5px',
                filter: 'drop-shadow(0px 5px 0px #E22B17)',
                transition: 'all 0.5s ease',
            }}
            onClick={onClick}
        >
            
            <Box
                sx={{
                    clipPath: `polygon(
                    0% 18px,
                    18px 0%,
                    calc(100% - 18px) 0%,
                    100% 18px,
                    100% calc(100% - 18px),
                    calc(100% - 18px) 100%,
                    18px 100%,
                    0% calc(100% - 18px)
                    )`,
                    transition: 'all 0.5s ease',
                    padding: label ? '16px 30px 3px' : '3px 30px',
                    backgroundColor: '#FFDA40',
                    width:'100%'
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Superstar',
                        fontSize: '32px',
                        color: '#090909',
                        textTransform: 'uppercase',
                        lineHeight: 'normal'
                    }}
                >
                    {text}
                </Typography>
            </Box>
            <img src='images/button/corner.svg' width={18} style={{ position: 'absolute', top: '5px', left: '0' }} alt="button" />
            <img src='images/button/corner.svg' width={18} style={{ position: 'absolute', top: '5px', right: '0', transform: 'scaleX(-1)' }} alt="button" />
            <img src='images/button/corner.svg' width={18} style={{ position: 'absolute', bottom: '5px', left: '0', transform: 'scale(1,-1)' }} alt="button" />
            <img src='images/button/corner.svg' width={18} style={{ position: 'absolute', bottom: '5px', right: '0', transform: 'scale(-1)' }} alt="button" />
            <img src='images/button/label.svg' style={{ position: 'absolute', top: '0', left: '50%', transform: 'translateX(-50%)', opacity: label? "100" : "0", transition: 'all 0.5s ease'}} alt='label' />
        </ButtonBase>

    )
}


export default Button