import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Button from './Common/Button';
import { BOT_URL } from '../constants';

export const SOCIALS = [
  { img: 'images/social/tg.svg', href: 'https://t.me/moonplay_official' },
  { img: 'images/social/x.svg', href: 'https://x.com/moonplay_games' },
  { img: 'images/social/discord.svg', href: 'https://discord.gg/HH9tD5eqxp' },
  { img: 'images/social/medium.svg', href: 'https://medium.com/@moonplay' },
];

function Header() {
  const [offset, setOffset] = useState(0);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:1280px)');

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = '';
    }
  }, [isMenuOpen]);

  const links = [
    { text: 'GAMES', href: 'games' },
    { text: 'BIG Announcement', href: 'annaunce' },
    { text: 'ROADMAP', href: 'roadmap' },
  ];

  const handleClick = useCallback(()=>{
    window.open(BOT_URL,'_blank')
  },[])

  const handleMobileClick = (href: string) => {
    const element_to_scroll_to = document.getElementById(href);
    setTimeout(() => {
      setMenuOpen(false);
      element_to_scroll_to?.scrollIntoView({ behavior: 'smooth' });
    }, 300);
  };

  return isMobile ? (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 9,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        height: isMenuOpen ? '100%' : undefined,
        background: isMenuOpen ? 'rgba(9, 9, 9, 0.5)' : undefined,
      }}
    >
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            background: offset > 0 || isMenuOpen ? 'rgba(40, 40, 40, 0.80)' : 'transparent',
            backdropFilter: offset > 0 || isMenuOpen ? 'blur(15px)' : undefined,
            height: offset > 0 ? '60px' : '80px',
            padding: '0 20px',
            transition: 'all 0.5s ease',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .appLogo': {
              transition: 'all 0.5s ease',
              width: '131px',
              height: '40px',
            },
          }}
        >
          <img className="appLogo" src={'images/Logo_long.webp'} alt="logo"></img>
          <img
            onClick={() => {
              setMenuOpen((prev) => !prev);
            }}
            src={isMenuOpen ? 'images/hero/close.svg' : 'images/hero/menu.svg'}
            alt="menu"
          ></img>
        </Box>
        {isMenuOpen && (
          <Box
            sx={{
              padding: '30px 20px 40px',
              transition: 'all 0.5s ease',
              background: offset > 0 || isMenuOpen ? 'rgba(40, 40, 40, 0.80)' : 'transparent',
              backdropFilter: offset > 0 || isMenuOpen ? 'blur(15px)' : undefined,
              '& button':{
                width:'100%'
              }
            }}
          >
            <Box
              sx={{
                transition: 'all 0.5s ease',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                marginBottom:'20px',
                '& p': {
                  color: 'white',
                  fontSize: '40px',
                  fontWeight: 400,
                  fontFamily: 'Superstar',
                  transition: 'all 0.2s ease',
                  cursor: 'pointer',
                  userSelect: 'none',
                  '&:hover': {
                    color: '#FFDA40',
                    textShadow: '0px 4px 0px #E22B17',
                  },
                },
              }}
            >
              {links.map((el) => (
                <Typography onClick={() => handleMobileClick(el.href)} key={el.text}>
                  {el.text}
                </Typography>
              ))}
              <Box
                sx={{
                  display: 'flex',
                  gap: '20px',
                  '& img': {
                    width: '42px',
                    height: '42px',
                  },
                }}
              >
                {SOCIALS.map((social) => (
                  <img
                    src={social.img}
                    alt={'social'}
                    key={social.href}
                    onClick={() => {
                      window.open(social.href, '_blank');
                    }}
                  ></img>
                ))}
              </Box>
            </Box>
            <Button label={true} text='TO THE MOON' onClick={handleClick}/>
          </Box>
        )}
      </Box>
    </Box>
  ) : (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 9,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        transition: 'all 0.5s ease',
        background: offset > 0 ? 'rgba(40, 40, 40, 0.80)' : 'transparent',
        backdropFilter: offset > 0 ? 'blur(15px)' : undefined,
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '1216px',
        }}
      >
        <Box
          sx={{
            height: offset > 0 ? '78px' : '144px',
            padding: '0 20px',
            transition: 'all 0.5s ease',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .appLogo': {
              transition: 'all 0.5s ease',
              width: offset > 0 ? '84px' : '112px',
              height: offset > 0 ? '48px' : '64px',
            },
          }}
        >
          <img className="appLogo" src={'images/Logo_short.webp'} alt="logo"></img>

          <Box
            sx={{
              display: 'flex',
              gap: '30px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '30px',
                '& p': {
                  color: 'white',
                  fontSize: '32px',
                  fontWeight: 400,
                  fontFamily: 'Superstar',
                  transition: 'all 0.2s ease',
                  cursor: 'pointer',
                  userSelect: 'none',
                  '&:hover': {
                    color: '#FFDA40',
                    textShadow: '0px 4px 0px #E22B17',
                  },
                },
              }}
            >
              {links.map((el) => (
                <Typography
                  onClick={() => {
                    const element_to_scroll_to = document.getElementById(el.href);
                    element_to_scroll_to?.scrollIntoView({ behavior: 'smooth' });
                  }}
                  key={el.text}
                >

                  {el.text}
                </Typography>
              ))}
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  minWidth: '100px',
                  '& img': {
                    cursor: 'pointer',
                    width: '42px',
                    height: '42px',
                  },
                }}
              >
                {SOCIALS.map((social) => (
                  <img
                    src={social.img}
                    key={social.href}
                    alt={'social'}
                    onClick={() => {
                      window.open(social.href, '_blank');
                    }}
                  ></img>
                ))}
              </Box>
            </Box>
            <Button label={!offset} text='TO THE MOON' onClick={handleClick} />
          </Box>


        </Box>
      </Box>
    </Box>
  );
}

export default Header;
