import { Box, ButtonBase, Typography, useMediaQuery } from "@mui/material"
import { useCallback } from "react"
import { BOT_URL } from "../constants"
import Container from "./Common/Container"

const Annaunce = () => {
    const isMobile = useMediaQuery('(max-width:1000px)');
    const handleClick = useCallback(() => {
        window.open(BOT_URL, '_blank')
    }, [])
    return <>
        <Box
            id='annaunce'
            sx={{
                width: '100%',
                backgroundImage: 'url(images/annaunce/bg.webp)',
                padding: '100px 0 0',
                backgroundSize: isMobile ? '774px auto' : '1659px 1374px',
                backgroundPosition: 'center bottom',
                backgroundRepeat: 'no-repeat',
                height: isMobile ? '540px' : '1150px',
                marginBottom: isMobile ? '88px' : '30px',
                position: 'relative'
            }}
        >
            <Container>
                {isMobile ? <Box
                    sx={{
                        margin: '0 auto',
                        textAlign: 'center',
                        width: 'max-content',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Superstar',
                            fontSize: '40px',
                            textShadow: '0px 5px 0px #E22B17',
                            lineHeight: 'normal',
                            color: '#FFDA40',
                            textTransform: 'uppercase'
                        }}
                    >
                        something
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            gap: '12px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Superstar',
                                fontSize: '40px',
                                textShadow: '0px 5px 0px #0D19F0',
                                lineHeight: 'normal',
                                color: '#15FFFE',
                                textTransform: 'uppercase'
                            }}
                        >
                            big
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Superstar',
                                fontSize: '40px',
                                textShadow: '0px 5px 0px #E22B17',
                                lineHeight: 'normal',
                                color: '#FFDA40',
                                textTransform: 'uppercase'
                            }}>
                            is coming!
                        </Typography>
                    </Box>

                </Box>
                    :
                    <Box
                        sx={{
                            display: 'flex',
                            margin: '0 auto',
                            gap: '25px',
                            width: 'max-content'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Superstar',
                                fontSize: '80px',
                                textShadow: '0px 10px 0px #E22B17',
                                lineHeight: 'normal',
                                color: '#FFDA40',
                                textTransform: 'uppercase'
                            }}
                        >
                            something
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Superstar',
                                fontSize: '80px',
                                textShadow: '0px 10px 0px #0D19F0',
                                lineHeight: 'normal',
                                color: '#15FFFE',
                                textTransform: 'uppercase'
                            }}
                        >
                            big
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Superstar',
                                fontSize: '80px',
                                textShadow: '0px 10px 0px #E22B17',
                                lineHeight: 'normal',
                                color: '#FFDA40',
                                textTransform: 'uppercase'
                            }}>
                            is coming!
                        </Typography>
                    </Box>
                }

                <img src='images/annaunce/Arrows_pointer.svg' alt="pointer" width={isMobile ? 35 : 70}
                    style={{
                        marginTop: isMobile ? '20px' : '80px',
                        marginLeft: '50%',
                        transform: 'translateX(-50%)'
                    }}
                />
                <img src='images/annaunce/logo.svg' alt="logo"
                    width={isMobile ? '242px' : '460px'}
                    style={{
                        position: 'absolute',
                        bottom: isMobile ? -58 : 0,
                        left: '50%',
                        transform: 'translateX(-50%)'
                    }}
                />

            </Container >
        </Box >
        <Box
            sx={{
                boxSizing: 'border-box',
                width: '100%',
                maxWidth: '804px',
                padding: isMobile ?  '0 20px' : '0',
                margin: '0 auto',
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                gap: isMobile ? '20px' : '30px'
            }}
        >
            <Typography
                sx={{
                    fontFamily: 'Avenir400',
                    textAlign: 'center',
                    color: '#BCC0C7',
                    fontSize: isMobile ? '18px':'24px',
                    lineHeight:'normal'
                }}
            >
                Go on an epic journey through the stars: build your spaceship, recruit your crew, and set off for the moon! Complete space missions to make sure you’re ready for the token airdrop.
            </Typography>
            <ButtonBase
                sx={{
                    width: isMobile ? "201px" : '253px',
                    height: isMobile ? "58px": '73px',
                    backgroundImage: 'url(images/annaunce/Button.webp)',
                    backgroundSize: 'contain',
                    margin: '0 auto'
                }}
                onClick={handleClick}
            />

        </Box>
    </>


}
export default Annaunce