import { Box } from "@mui/material"
import { ReactNode } from "react"

const Container = ({children}:{children: ReactNode})=> {
    return (
        <Box
           sx={{
                width:'100%',
                maxWidth:'1216px',
                margin: '0 auto'
           }} 
        > 
            {children}
        </Box>
    )
}
export default Container