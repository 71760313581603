import React, { useEffect, useMemo, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { GAMES, GamesItemType } from './Games.constants';
import Container from '../Common/Container';

function GameCardDesktop({ item, active }: { item: GamesItemType; active: boolean }) {
  return (
    <Box
      sx={{
        width: '907px',
        minHeight: '500px',
        height: '100%',
        display: 'flex',
        backgroundImage: 'url(images/games/portfolio_front_image.webp)',
        backgroundSize: 'cover',
        transition: 'all 0.5s ease',
        opacity: active ? 1 : 0.2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '111px',
          padding: '60px 50px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '80px',
                height: '80px',
                borderRadius: '20px',
                backgroundImage: `url(${item.icon})`,
                backgroundSize: 'cover',
              }}
            ></Box>
            <Typography
              sx={{
                color: '#FFF',
                fontFamily: 'Avenir600',
                fontSize: '24px',
                lineHeight: 'normal',
              }}
            >
              {item.name}
            </Typography>
          </Box>
          <Typography
            sx={{
              flexGrow: 1,
              color: '#BCC0C7',
              fontFamily: 'Avenir400',
              fontSize: '20px',
              lineHeight: 'normal',
              marginTop: '30px',
            }}
          >
            {item.text}
          </Typography>
          <Box
            sx={{
              gap: '20px',
              display: 'flex',
              '& a': {
                display: 'block',
              },
            }}
          >
            {item.appStoreUrl && (
              <a href={item.appStoreUrl}>
                <img src="images/games/app_store.svg" alt="app store"></img>
              </a>
            )}
            {item.googlePlayUrl && (
              <a href={item.googlePlayUrl}>
                <img src="images/games/google_play.svg" alt="google play"></img>
              </a>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            minWidth: '260px',
            height: '430px',
            backgroundImage: `url(${item.image})`,
            backgroundSize: 'cover',
          }}
        ></Box>
      </Box>
    </Box>
  );
}
function GameCardMobile({ item, active }: { item: GamesItemType; active: boolean }) {
  return (
    <Box
      sx={{
        maxWidth: '315px',
        minHeight: '560px',
        height: '100%',
        display: 'flex',

        transition: 'all 0.5s ease',
        opacity: active ? 1 : 0.2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '0px 40px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box
            sx={{
              width: '64px',
              height: '64px',
              borderRadius: '16px',
              marginTop: '-22px',
              backgroundImage: `url(${item.icon})`,
              backgroundSize: 'cover',
            }}
          ></Box>
          <Typography
            sx={{
              color: '#FFF',
              fontFamily: 'Avenir600',
              fontSize: '24px',
              lineHeight: 'normal',
              textAlign: 'center',
              marginTop: '15px',
              marginBottom: '15px',
            }}
          >
            {item.name}
          </Typography>
          <Box
            sx={{
              gap: '11px',
              display: 'flex',
              '& a': {
                display: 'block',
                width: '112px',
                height: '36px',
                '& img': {
                  position: 'absolute',
                  zIndex: 9,
                  width: '112px',
                  height: '36px',
                },
              },
            }}
          >
            {item.appStoreUrl && (
              <a href={item.appStoreUrl}>
                <img src="images/games/app_store.svg" alt="app store"></img>
              </a>
            )}
            {item.googlePlayUrl && (
              <a href={item.googlePlayUrl}>
                <img src="images/games/google_play.svg" alt="google play"></img>
              </a>
            )}
          </Box>

          <Box
            sx={{
              marginTop: '30px',
              minWidth: '235px',
              height: '418px',
              backgroundImage: `url(${item.mob_image})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              marginBottom: '20px',
            }}
          ></Box>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              backgroundImage: 'url(images/games/portfolio_front_image_mob.webp)',
              backgroundSize: 'cover',
            }}
          ></Box>
        </Box>
      </Box>
    </Box>
  );
}

function Games() {
  const isMobile = useMediaQuery('(max-width:1000px)');
  const [activeIndex, setActiveIndex] = useState(1);
  const [swiper, setSwiper] = React.useState<SwiperCore | null>(null);
  const prevButtonRef = React.useRef<HTMLButtonElement>(null);
  const nextButtonRef = React.useRef<HTMLButtonElement>(null);

  function handlePrev() {
    if (!swiper) return;
    if (swiper.realIndex === 1) {
      swiper.slideTo(sliderData.length + 1, 0, false)
      swiper.slidePrev();
    }
    else {
      swiper.slidePrev();

    }
  }

  function handleNext() {
    if (!swiper) return;
    if (swiper.realIndex === sliderData.length + 1) {
      swiper.slideTo(0, 0, false)
      swiper.slideNext()
    }
    else {
      swiper.slideNext();

    }
  }
  useEffect(() => {
    if (!swiper || swiper.realIndex) return
    swiper.slideTo(sliderData.length + 1, 0, false)
    swiper.slidePrev();
  }, [swiper?.realIndex])

  function setIndex(index: number) {
    return () => {
      if (!swiper) return;
      swiper.slideTo(index);
    };
  }
  const sliderData = useMemo(() => {
    return GAMES;
  }, []);

  return (
    <Box
      id="games"
      sx={{
        scrollMarginTop: '78px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: isMobile ? '60px' : '120px',
        overflow: 'hidden',
      }}
    >
      {isMobile ? (
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              padding: '0 20px 40px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >

            <Typography
              sx={{
                color: '#FFDA40',
                fontFamily: 'Superstar',
                fontSize: '40px',
                fontWeight: 400,
                textShadow: '0px 5px 0px #E22B17',
                lineHeight: 'normal',
                textAlign: 'center',
              }}
            >
              meet our
              games
            </Typography>

          </Box>
          <Box
            sx={{
              maxWidth: '315px',
              overflow: 'visible',
              padding: '0 20px 0',
              margin: '0 auto',
              '& .swiper': {
                overflow: 'initial',
              },
              '& .swiper-slide': {
                width: '315px',
                height: '560px',
              },
              '& .swiper-container': {
                overflow: 'initial',
              },
            }}
          >
            <Swiper
              onSwiper={setSwiper}
              onActiveIndexChange={(swiper: SwiperCore) => {
                if (swiper.isEnd && activeIndex) {
                  swiper.slideTo(0, 0, false)
                  swiper.slideNext()
                  setActiveIndex(1)
                } else {
                  setActiveIndex(swiper.realIndex);

                }
              }}
              slidesPerView="auto"
              spaceBetween={10}
              speed={600}
              initialSlide={1}
              grabCursor
              loop
              navigation={{
                prevEl: prevButtonRef.current,
                nextEl: nextButtonRef.current,
              }}
            >
              <SwiperSlide key={'prev'} >
                <GameCardMobile item={sliderData[sliderData.length - 1]} active={false} key={'prev'} />
              </SwiperSlide>
              {sliderData.map((item, index) => (
                <SwiperSlide key={index} >
                  <GameCardMobile item={item} active={activeIndex - 1 === index} key={index} />
                </SwiperSlide>
              ))}
              <SwiperSlide key={'next'} >
                <GameCardMobile item={sliderData[0]} active={false} key={'next'} />
              </SwiperSlide>
            </Swiper>
          </Box>
        </Box>
      ) : (
        <Container>

          <Box
            sx={{
              padding: '0 20px 60px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                color: '#FFDA40',
                fontFamily: 'Superstar',
                fontSize: '80px',
                fontWeight: 400,
                textShadow: '0px 10px 0px #E22B17',
                lineHeight: 'normal',
              }}
            >
              meet our games
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '30px',
              }}
            >
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative'
            }}
          >
            <Box
              ref={prevButtonRef}
              onClick={handlePrev}
              sx={{
                backgroundImage: 'url(images/games/arrow.svg)',
                width: '40px',
                height: '70px',
                position: 'absolute',
                left: 0,
                top: '50%',
                transform: 'scaleX(-1) translateY(-50%)',
                zIndex: '50',
                cursor: 'pointer'
              }}
            />

            <Box
              sx={{
                overflow: 'visible',
                padding: '0 20px 0',
                margin: '0 auto',
                maxWidth: '907px',
                '& .swiper': {
                  overflow: 'initial',
                },
                '& .swiper-slide': {
                  width: '907px',
                  height: '550px',
                },
                '& .swiper-container': {
                  overflow: 'initial',
                },
              }}
            >
              <Swiper
                onSwiper={setSwiper}
                onActiveIndexChange={(swiper: SwiperCore) => {
                  if (swiper.isEnd && activeIndex) {
                    swiper.slideTo(0, 0, false)
                    swiper.slideNext()
                    setActiveIndex(1)
                  } else {
                    setActiveIndex(swiper.realIndex);

                  }
                }}
                slidesPerView="auto"
                spaceBetween={40}
                speed={600}
                initialSlide={1}
                grabCursor
                loop
                navigation={{
                  prevEl: prevButtonRef.current,
                  nextEl: nextButtonRef.current,
                }}
              >
                <SwiperSlide key={'prev'} >
                  <GameCardDesktop item={sliderData[sliderData.length - 1]} active={false} key={'prev'} />
                </SwiperSlide>
                {sliderData.map((item, index) => (
                  <SwiperSlide key={index} >
                    <GameCardDesktop item={item} active={activeIndex - 1 === index} key={index} />
                  </SwiperSlide>
                ))}
                <SwiperSlide key={'next'} >
                  <GameCardDesktop item={sliderData[0]} active={false} key={'next'} />
                </SwiperSlide>
              </Swiper>
            </Box>
            <Box
              ref={nextButtonRef}
              onClick={handleNext}
              sx={{
                backgroundImage: 'url(images/games/arrow.svg)',
                width: '40px',
                height: '70px',
                position: 'absolute',
                right: 0,
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: '50',
                cursor: 'pointer'
              }}
            />

          </Box>
          <Box
            sx={{
              color: 'white',
              display: 'flex',
              width: 'max-content',
              gap: '30px',
              margin: "50px auto 0",
              paddingBottom: '10px'
            }}
          >
            {sliderData.map((el, index) => (
              <Box
                key={index}
                onClick={setIndex(index)}
                sx={{
                  width: '10px',
                  height: '10px',
                  background: '#FFF',
                  transition: 'all 0.5s ease',
                  cursor: 'pointer',
                  opacity: index === activeIndex - 1 ? 1 : 0.2,
                  position: 'relative'
                }}
              >
                {index === activeIndex - 1 && <Box
                  sx={{
                    boxSizing: "border-box",
                    width: '30px',
                    height: '30px',
                    border: '5px solid #FFF',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                />}
              </Box>
            ))}
          </Box>
        </Container>
      )}
    </Box>
  );
}

export default Games;
