import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Container from './Common/Container';

function Divider() {
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: isMobile ? 'row' : 'column',
        alignItems: 'center',
        gap: isMobile ? '10px' : '20px',
        padding: '20px 10px',
        '& .top': {
          width: isMobile ? '5px' : '10px',
          height: isMobile ? '5px' : '10px',
          background: '#15FFFE',
        },
        '& .bottom': {
          width: isMobile ? '5px' : '10px',
          height: isMobile ? '5px' : '10px',
          background: '#0D19F0',
        },
      }}
    >
      <Box>
        <Box className="top"></Box>
        <Box className="bottom"></Box>
      </Box>
      <Box>
        <Box className="top"></Box>
        <Box className="bottom"></Box>
      </Box>
    </Box>
  );
}
function Roadmap() {
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <Box
      id="roadmap"
      sx={{
        scrollMarginTop: '78px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: isMobile ? '30px' : '70px',
        marginBottom: isMobile ? '0px' : '120px',
        backgroundImage: isMobile ? '' : 'url(images/roadmap/Decoration.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition:'center top'
      }}
    >
      <Container>
        <Box
          sx={{
            padding: isMobile ? '0' : '0 20px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              width: '100%',
            }}
          >
            {isMobile ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: '13px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '30px',
                }}
              >
                <img src="images/roadmap/arrows_left.svg" alt="decor"></img>

                <Typography
                  sx={{
                    color: '#FFDA40',
                    fontFamily: 'Superstar',
                    fontSize: '40px',
                    fontWeight: 400,
                    textShadow: '0px 5px 0px #E22B17',
                  }}
                >
                  Roadmap
                </Typography>
                <img src="images/roadmap/arrows.svg" alt="decor"></img>
              </Box>
            ) : (
              <Typography
                sx={{
                  color: '#FFDA40',
                  fontFamily: 'Superstar',
                  fontSize:'80px',
                  lineHeight:"normal",
                  textShadow: '0px 10px 0px #E22B17',
                  textAlign:'center',
                  marginBottom: '30px'

                }}
              >
                UPCOMING PROJECTS <br />
                ROADMAP
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              width: '100%',
              maxWidth: isMobile ? undefined : '907px',
              display: isMobile ? 'flex' : 'block',
              overflowX: 'auto',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
              margin: '0 auto',

              '& .wrapper': {
                height: isMobile ? '265px' : '530px',
                backgroundImage: 'url(images/roadmap/roadmap_border.webp)',
                backgroundSize: '100% 100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: isMobile ? '0 26px' : '0',
                '& p': {
                  color: '#FFDA40',
                  fontFamily: 'Superstar',
                  fontSize: isMobile ? '20px' : '40px',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  marginTop: isMobile ? '20px' : '50px',
                  marginBottom: isMobile ? '15px' : '30px',
                },
                '& .cards': {
                  display: 'flex',
                  gap: isMobile ? '10px' : '20px',
                  '& img': {
                    width: isMobile ? '92px' : '185px',
                    height: isMobile ? '143' : '285px',
                  },
                  '& p': {
                    color: '#FFF',
                    fontSize: isMobile ? '10px' : '20px',
                    maxWidth: '185px',
                    textAlign: 'center',
                    lineHeight: 'normal',
                    margin: isMobile ? '15px 0' : '30px 0',
                  },
                },
              },
            }}
          >
            <Box
              className="wrapper"
              sx={{
                marginLeft: isMobile ? '20px' : undefined,
              }}
            >
              <Typography>q3 2024</Typography>
              <Box className="cards">
                <Box>
                  <img src="images/roadmap/game_screenshot_1.webp" alt="game"></img>
                  <Typography>
                    Hyper-casual
                    <br />
                    simulation
                  </Typography>
                </Box>
                <Box>
                  <img src="images/roadmap/game_screenshot_2.webp" alt="game"></img>
                  <Typography>
                    Hyper-Casual
                    <br />
                    Puzzle
                  </Typography>
                </Box>
                <Box>
                  <img src="images/roadmap/game_screenshot_3.webp" alt="game"></img>
                  <Typography>
                    Life
                    <br />
                    Simulation
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box className="wrapper">
              <Typography>q4 2024</Typography>
              <Box className="cards">
                <Box>
                  <img src="images/roadmap/game_screenshot_4.webp" alt="game"></img>
                  <Typography>
                    Hyper-casual
                    <br />
                    simulation
                  </Typography>
                </Box>
                <Box>
                  <img src="images/roadmap/game_screenshot_5.webp" alt="game"></img>
                  <Typography>
                    Logic
                    <br />
                    Puzzle
                  </Typography>
                </Box>
                <Box>
                  <img src="images/roadmap/game_screenshot_6.webp" alt="game"></img>
                  <Typography>
                    LifeStyle
                    <br />
                    customization
                  </Typography>
                </Box>
                <Box>
                  <img src="images/roadmap/game_screenshot_7.webp" alt="game"></img>
                  <Typography>
                    Block
                    <br />
                    Puzzle
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box className="wrapper">
              <Typography>q1 2025</Typography>
              <Box className="cards">
                <Box>
                  <img src="images/roadmap/game_screenshot_8.webp" alt="game"></img>
                  <Typography>
                    Sports
                    <br />
                    Simulation
                  </Typography>
                </Box>
                <Box>
                  <img src="images/roadmap/game_screenshot_9.webp" alt="game"></img>
                  <Typography>
                    Rhythm
                    <br />& Music
                  </Typography>
                </Box>
                <Box>
                  <img src="images/roadmap/game_screenshot_10.webp" alt="game"></img>
                  <Typography>
                    Life
                    <br />
                    Simulation
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box className="wrapper">
              <Typography>q2 2025</Typography>
              <Box className="cards">
                <Box>
                  <img src="images/roadmap/game_screenshot_11.webp" alt="game"></img>
                  <Typography>Words</Typography>
                </Box>
                <Box>
                  <img src="images/roadmap/game_screenshot_12.webp" alt="game"></img>
                  <Typography>
                    Singleplayer
                    <br />
                    Card Games
                  </Typography>
                </Box>
                <Box>
                  <img src="images/roadmap/game_screenshot_13.webp" alt="game"></img>
                  <Typography>
                    Hyper-Casual
                    <br />
                    Puzzle
                  </Typography>
                </Box>
                <Box>
                  <img src="images/roadmap/game_screenshot_14.webp" alt="game"></img>
                  <Typography>
                    Hyper-Casual
                    <br />
                    .IO
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box
              className="wrapper"
              sx={{
                marginRight: isMobile ? '20px' : undefined,
              }}
            >
              <Typography>q3 2025</Typography>
              <Box className="cards">
                <Box>
                  <img src="images/roadmap/game_screenshot_15.webp" alt="game"></img>
                  <Typography>
                    Hyper-Casual
                    <br />
                    Runner
                  </Typography>
                </Box>
                <Box>
                  <img src="images/roadmap/game_screenshot_16.webp" alt="game"></img>
                  <Typography>
                    Logic
                    <br />
                    Puzzle
                  </Typography>
                </Box>
                <Box>
                  <img src="images/roadmap/game_screenshot_17.webp" alt="game"></img>
                  <Typography>
                    Racing
                    <br />
                    Simulator
                  </Typography>
                </Box>
                <Box>
                  <img src="images/roadmap/game_screenshot_18.webp" alt="game"></img>
                  <Typography>
                    Match
                    <br />& Build
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {
            isMobile && <Box
              sx={{
                margin: '60px 0 40px',
                height: "220px",
                width: '100%',
                backgroundImage: 'url(images/roadmap/Invaders.svg)'
              }}
            />
          }
        </Box>
      </Container >
    </Box>
  );
}

export default Roadmap;
