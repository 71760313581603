import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { SOCIALS } from './Header';

function Footer() {
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        background: '#090909',
        flexDirection: 'column',
        margin: isMobile ? '40px 0' : '60px 0',
        gap: '20px',
      }}
    >
      <img src='images/moonplay.svg' width={'100%'} style={{ maxWidth: '1216px', margin: '0 auto 60px' }} />
      <Typography
        onClick={() => {
          window.open('mailto:contact@moonplay.io', '_blank');
        }}
        sx={{
          color: '#FFDA40',
          fontFamily: 'Superstar',
          fontSize: isMobile ? '20px' : '40px',
          fontWeight: 400,
          lineHeight: 'normal',
          textAlign: 'center',
          cursor: 'pointer',
          transition: 'all 0.5s ease',
          '&:hover': {
            color: '#E22B17',
          },
        }}
      >
        contact@MOONPLAY.io
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          justifyContent: 'center',
          '& img': {
            cursor: 'pointer',
            width: '42px',
            height: '42px',
          },
        }}
      >
        {SOCIALS.map((social) => (
          <img
            src={social.img}
            alt={'social'}
            key={social.href}
            onClick={() => {
              window.open(social.href, '_blank');
            }}
          ></img>
        ))}
      </Box>
      <Typography
        sx={{
          color: '#FFF',
          fontFamily: 'Superstar',
          fontSize: isMobile ? '20px' : '40px',
          fontWeight: 400,
          lineHeight: 'normal',
          textAlign: 'center',
        }}
      >
        2024. All rights reserved
      </Typography>
    </Box>
  );
}

export default Footer;
