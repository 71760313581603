import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';

function Hero() {
  const isMobile = useMediaQuery('(max-width:1000px)');

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
      </Box>
      {isMobile ? (
        <Box
          sx={{
            width: '100%',
            overflow: 'hidden',
            height: '330px',
            display: 'flex',
            justifyContent: 'center',
            gap: '55px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              maxWidth: '310px',
              position: 'relative',
            }}
          >
            <img width="159px" height="330px" src={'images/hero/portals_decoration_left.svg'} alt="decoration"></img>
            <img
              style={{
                position: 'relative',
                zIndex: 3,
              }}
              width="160px"
              height="330px"
              src={'images/hero/portals_top_left.svg'}
              alt="decoration"
            ></img>
            <img
              style={{ position: 'absolute', right: 0 }}
              width="160px"
              height="330px"
              src={'images/hero/portals_bottom_left.webp'}
              alt="decoration"
            ></img>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              zIndex: 2,
              display: 'flex',
              maxWidth: '360px',
              width: '100%',
              marginTop: '35px',
              height: '260px',
              backgroundImage: 'url(images/hero/games.webp)',
              backgroundSize: '840px 260px',
              animation: 'moveLeftMobile 20s infinite linear',
            }}
          ></Box>
          <Box
            sx={{
              display: 'flex',
              width: '310px',
              position: 'relative',
            }}
          >
            <img
              style={{ position: 'absolute', left: 0, zIndex: 1 }}
              width="160px"
              height="330px"
              src={'images/hero/portals_bottom_right.webp'}
              alt="decoration"
            ></img>
            <img
              style={{
                position: 'relative',
                zIndex: 3,
              }}
              width="160px"
              height="330px"
              src={'images/hero/portals_top_right.svg'}
              alt="decoration"
            ></img>
            <img width="150px" height="330px" src={'images/hero/portals_decoration_right.svg'} alt="decoration"></img>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            overflow: 'hidden',
            height: '660px',
            display: 'flex',
            justifyContent: 'center',
            gap: '570px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '620px',
              position: 'relative',
            }}
          >
            <img width="300px" height="660px" src={'images/hero/portals_decoration_left.svg'} alt="decoration"></img>
            <img
              style={{
                position: 'relative',
                zIndex: 3,
              }}
              width="320px"
              height="660px"
              src={'images/hero/portals_top_left.svg'}
              alt="decoration"
            ></img>
            <img
              style={{ position: 'absolute', right: 0 }}
              width="320px"
              height="660px"
              src={'images/hero/portals_bottom_left.webp'}
              alt="decoration"
            ></img>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              zIndex: 2,
              display: 'flex',
              maxWidth: '1200px',
              width: '100%',
              marginTop: '70px',
              height: '520px',
              backgroundImage: 'url(images/hero/games.webp)',
              backgroundSize: '1680px 520px',
              animation: 'moveLeft 20s infinite linear',
            }}
          ></Box>
          <Box
            sx={{
              display: 'flex',
              width: '620px',
              position: 'relative',
            }}
          >
            <img
              style={{ position: 'absolute', left: 0, zIndex: 1 }}
              width="320px"
              height="660px"
              src={'images/hero/portals_bottom_right.webp'}
              alt="decoration"
            ></img>
            <img
              style={{
                position: 'relative',
                zIndex: 3,
              }}
              width="320px"
              height="660px"
              src={'images/hero/portals_top_right.svg'}
              alt="decoration"
            ></img>
            <img width="300px" height="660px" src={'images/hero/portals_decoration_right.svg'} alt="decoration"></img>
          </Box>
        </Box>
      )}
    </>
  );
}

export default Hero;
