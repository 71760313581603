import { Box } from '@mui/material';
import Annaunce from './Annaunce';
import Footer from './Footer';
import Games from './Games/Games';
import Header from './Header';
import Hero from './Hero';
import Intro from './Intro';
import Roadmap from './Roadmap';

function Main() {
  return (
    <Box
      sx={{
        width: '100%',
        background: '#090909',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Header />
      <Intro/>
      <Games />
      <Annaunce/>
      <Roadmap />
      <Hero />

      <Footer />
    </Box>
  );
}

export default Main;
